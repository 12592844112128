import { Routes, Route, Navigate } from "react-router-dom";

import { SAppContainer } from "../styles";
import Teams from "./Teams";
import TeamInfo from "./Teams/TeamInfo";
import Players from "./Players";
import AppHeader from "./AppHeader";
import PlayerInfo from "./PlayerInfo";
import Traits from "./Traits";
import Profile from "./Profile";
import { Helmet } from "react-helmet";
import { CANONICAL_URL } from "../constants";
import {
  profileRoute,
  registerRoute,
  loginRoute,
  traitsRoute,
  aboutRoute,
  teamsRoute,
  teamViewPath,
  rootRoute,
  playerPath,
  pitcherRoute,
  userTeamCreateRoute,
  userTeamEditRoute,
  userTeamViewRoute,
  fieldPlayerRoute,
  sharedTeamsRoute,
  sharedTeamViewRoute,
} from "../constants";
import Register from "./Auth/RegisterForm";
import Login from "./Auth/LoginForm";
import TeamCreate from "./Profile/TeamCreate";
import TeamEdit from "./Profile/TeamCreate/TeamEdit";
import Home from "./Home";
import SharedTeams from "./SharedTeams";
import TeamStats from "./Teams/TeamStats";
import TeamAttributes from "./Teams/TeamAttributes";
import AboutPage from "./About";

const App = () => (
  <SAppContainer direction="column">
    <Helmet titleTemplate={`%s - ${document.title}`}>
      <link rel="canonical" href={CANONICAL_URL} />
    </Helmet>
    <AppHeader />
    <Routes>
      <Route path={rootRoute} element={<Home />} />

      <Route path={registerRoute} element={<Register />} />
      <Route path={loginRoute} element={<Login />} />

      <Route path={profileRoute} element={<Profile />} />
      <Route path={userTeamEditRoute} element={<TeamEdit />} />
      <Route path={userTeamCreateRoute} element={<TeamCreate />} />

      {[userTeamViewRoute, sharedTeamViewRoute].map((path) => (
        <Route key={path} path={path} element={<TeamInfo />}>
          <Route path={"stats"} element={<TeamStats />} />
          <Route path={"overview"} element={<TeamAttributes />}>
            <Route path={playerPath} element={<PlayerInfo />} />
          </Route>
          <Route path="" element={<Navigate to="overview" />} />
        </Route>
      ))}

      <Route path={sharedTeamsRoute} element={<SharedTeams />} />
      <Route path={traitsRoute} element={<Traits />} />
      <Route path={teamsRoute} element={<Teams />} />
      <Route path={aboutRoute} element={<AboutPage />} />

      <Route path={teamViewPath} element={<TeamInfo />}>
        <Route path={""} element={<TeamAttributes />}>
          <Route path={playerPath} element={<PlayerInfo />} />
        </Route>
      </Route>

      {[fieldPlayerRoute, pitcherRoute].map((path) => (
        <Route key={path} path={path} element={<Players />}>
          <Route path={playerPath} element={<PlayerInfo />} />
        </Route>
      ))}
    </Routes>
  </SAppContainer>
);

export default App;
