import { SAboutContainer, SAnchor } from "../styles";

const AboutPage = () => {
  return (
    <SAboutContainer>
      <div className="row">
        <h3>What is this site?</h3>
        <p>
          I built this site to review all players, traits, and teams of Super
          Mega Baseball 4. I built another site for{" "}
          <SAnchor
            target="_blank"
            href="https://redundantrobot.com/super_mega_baseball"
            rel="noreferrer"
          >
            Super Mega Baseball 3
          </SAnchor>{" "}
          originally, but with this site I wanted a bigger insight into the
          game.
        </p>
      </div>

      <div className="row">
        <h3>How did I do it?</h3>
        <p>
          I used the SQLite database from the PC version of the game to extract
          a majority of players and teams data. Various other users from the{" "}
          <SAnchor
            href="https://www.reddit.com/r/SuperMegaBaseball/"
            target="_blank"
          >
            SMB4 subreddit
          </SAnchor>{" "}
          helped fill in some gaps here and there.
        </p>
        <p>
          I extracted the images by taking screen shots of each team grid of
          players. I wrote some scripts to extract each image and save them
          separately. All of these images are{" "}
          <SAnchor
            target="_blank"
            href="https://drive.google.com/drive/u/0/folders/1soDJ7l0xWuqoJYpmtlRZiOwbuHbR7DwD"
            rel="noreferrer"
          >
            avaialable here.
          </SAnchor>
        </p>
      </div>

      <div className="row">
        <a href="https://www.buymeacoffee.com/thetrav">
          <a href="https://www.buymeacoffee.com/thetrav">
            <img
              alt="buy coffee link"
              src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=thetrav&button_colour=BD5FFF&font_colour=ffffff&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00"
            />
          </a>
        </a>
      </div>
    </SAboutContainer>
  );
};

export default AboutPage;
