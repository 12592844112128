import { useState } from "react";
import { useAppContext } from "../AppContext";
import {
  SAppHeader,
  SLinksContainer,
  SMenuBtn,
  SFlex,
  SHeader,
  SLink,
} from "../styles";
import HeaderLink from "./HeaderLink";
import Image from "./Image";
import {
  aboutRoute,
  fieldPlayerRoute,
  pitcherRoute,
  profileRoute,
  teamsRoute,
  traitsRoute,
} from "../constants";

const AppHeader = () => {
  const { isLoggedIn } = useAppContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <SAppHeader $alignItems="center">
      <SFlex gap="5px">
        <Image
          src={`${process.env.PUBLIC_URL}/baseball-120.png`}
          style={{ height: "30px", marginTop: "8px" }}
        />
        <SLink to="/" style={{ textDecoration: "none" }}>
          <SFlex direction="column">
            <SHeader>Really Epic Hardball</SHeader>
            <h5>Super Mega Baseball 4</h5>
          </SFlex>
        </SLink>
      </SFlex>
      <SLinksContainer gap="5px" $isOpen={isOpen} onClick={() => close()}>
        <HeaderLink to={fieldPlayerRoute} text="Players" />
        <HeaderLink to={pitcherRoute} text="Pitchers" />
        <HeaderLink to={teamsRoute} text="Teams" />
        <HeaderLink to={traitsRoute} text="Traits" />
        <HeaderLink to={aboutRoute} text="About" />
        {isLoggedIn && <HeaderLink to={profileRoute} text="Profile" />}
      </SLinksContainer>
      <SMenuBtn
        onClick={() => setIsOpen(!isOpen)}
        $isOpen={isOpen}
        direction="column"
        justify="space-between"
        gap="5px"
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </SMenuBtn>
    </SAppHeader>
  );
};

export default AppHeader;
